import { SearchType } from '../../../services/place-search/models/search-type.enum';
import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import * as L from 'leaflet';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { Proj4GeoJSONFeature } from 'proj4leaflet';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';
import { IPlaceSearchItem } from 'services/place-search/models/place-search-item.interface';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { FeatureEventType } from 'vv-constants/feature-event-type.enum';
import { IMapRoutePoint } from 'services/application-repository/models/map-route-point.interface';
import { ILocationEvent } from 'services/application-repository/models/location-event.interface';
import { MapConfiguration } from 'services/map-configuration/map-configuration';


@autoinject()
export class SearchPopup {

	private crs: L.Proj.CRS;
	private readonly locationZoom: number;
   
	constructor(
		private eventAggregator: EventAggregator, 
		private mapConfiguration: MapConfiguration,
		private applicationRepository: ApplicationRepository,
		private config: EnvironmentConfiguration) {

		this.locationZoom = this.config.env.LocationZoom;

		this.crs = this.mapConfiguration.getMapCrs().crs;
	}

	public addSearchPopup(map: L.Map, payload: any): void {
		const placeSearchItem: IPlaceSearchItem = payload.placeSearchItem;
		const location = this.crs.unproject(payload.location);

		if (placeSearchItem.geometry) {
			this.addSearchGeometryPopup(placeSearchItem, location, map);
		}
		else {
			const container = this.createPopupContainer(placeSearchItem);
			L.popup()
				.setLatLng(location)
				.setContent(container).openOn(map);
	
			map.setView(location, this.locationZoom);
		}
	}
	
	public getSearchPopup(payload: IMapRoutePoint) : L.Popup {
		const container = this.createPopupContainer(payload);
		return L.popup().setContent(container);
	}

	public getSearchPopupContent(payload: IMapRoutePoint): HTMLElement {
		return this.createPopupContainer(payload);
	}

	/**
	 * Creates content for Search result
	 * @param payload 
	 */
	private createPopupContainer(payload: IPlaceSearchItem): HTMLElement {
		const jsonPayload = JSON.stringify(payload);
		const contatiner = L.DomUtil.create('div', 'search-popup leaflet-contextmenu');
		contatiner.style.display = 'block';
		const header = L.DomUtil.create('h4', 'leaflet-contextmenu-item');
		header.style.padding = '0 12px';
		header.innerText = payload.name;
		contatiner.appendChild(header);

		if (payload.type === "Site") {
			const setId = L.DomUtil.create('h4');
			setId.style.padding = '0 12px';
			setId.innerText = payload.id;
			const setCords = L.DomUtil.create('h5');
			setCords.style.padding = '0 12px';
			setCords.innerText = 'X: ' + payload.easting +' Y: '+ payload.northing;
			contatiner.appendChild(setId);
			contatiner.appendChild(setCords);
		}
		
		const setStartLocation = L.DomUtil.create('a');
		setStartLocation.className = "leaflet-contextmenu-item";
		setStartLocation.innerHTML =  '<span style="font-size:16px; padding-right:10px;" class="icon-icon-map-start"><span class="path1"></span></span>Rutt härifrån<br/>';
		const startLocationEvent = JSON.parse(jsonPayload) as ILocationEvent;
		startLocationEvent.locationType = "START";
		setStartLocation.onclick = (): void => {
			this.eventAggregator.publish(MapEventType.MAP_LOCATION_SELECTED, startLocationEvent);
		};

		const setViaLocation = L.DomUtil.create('a');
		setViaLocation.className = "leaflet-contextmenu-item";
		setViaLocation.innerHTML = '<span style="font-size:16px; padding-right:10px;" class="icon-icon-map-via"><span class="path1"></span><span class="path2"></span></span>Rutt via<br/>';
		
		if (this.applicationRepository.canAddViaPoint()) {
			const viaLocationEvent = JSON.parse(jsonPayload) as ILocationEvent;
			viaLocationEvent.locationType = "VIA";
			setViaLocation.onclick = (): void => {
				this.eventAggregator.publish(MapEventType.MAP_LOCATION_SELECTED, viaLocationEvent);
			};
		}
		else {
			setViaLocation.classList.add('leaflet-contextmenu-item-disabled');
		}
		
		const setEndLocation = L.DomUtil.create('a');
		setEndLocation.className = "leaflet-contextmenu-item";
		setEndLocation.innerHTML =  '<span style="font-size:16px; padding-right:10px;" class="icon-icon-map-stopp"><span class="path1"></span><span class="path2"></span></span>Rutt hit<br/>';
		const endLocationEvent = JSON.parse(jsonPayload) as ILocationEvent;
		endLocationEvent.locationType = "END";
		setEndLocation.onclick = (): void => {
			this.eventAggregator.publish(MapEventType.MAP_LOCATION_SELECTED, endLocationEvent);
		};

		if (!this.applicationRepository.canAddLocations()) {
			setStartLocation.classList.add('leaflet-contextmenu-item-disabled');
			setViaLocation.classList.add('leaflet-contextmenu-item-disabled');
			setEndLocation.classList.add('leaflet-contextmenu-item-disabled');
		}

		contatiner.appendChild(setStartLocation);
		contatiner.appendChild(setViaLocation);
		contatiner.appendChild(setEndLocation);

		//TODO: Detta är bortkommenterat tills det att vi löst man kan replikera tp-led så att det kan ha flera värden av ett attribut som är en array. 
		if (payload.type === SearchType.SITE) {
			const showTp = L.DomUtil.create('a');
			showTp.className = "leaflet-contextmenu-item";
			showTp.innerHTML =  '<span style="font-size:14px; color:#FF0000;" class="fa fa-minus"></span>Visa TP-leder<br/>';
			showTp.title = 'Klicka för att visa TP-leder';

			showTp.onclick = (): void => {
				this.eventAggregator.publish(FeatureEventType.SHOW_TP_LEDER_FOR, payload);
			};

			contatiner.appendChild(showTp);

			const toggleConfig = this.config.env.FeatureToggle;
			if (toggleConfig.IsViol3) {
				const showStorage = L.DomUtil.create('a');
				showStorage.className = "leaflet-contextmenu-item";
				showStorage.innerHTML = '<span style="padding-right:8px" class="icon-storage-site-'+payload.siteType+'"><span class="path1"></span></span>Visa lagerplatser<br/>';
				showStorage.title = 'Klicka för att visa lagerplatser';

				showStorage.onclick = (): void => {
					this.eventAggregator.publish(FeatureEventType.SHOW_STORAGE_SITES_FOR, payload);
				}
				contatiner.appendChild(showStorage);
			}
		}

		return contatiner;
	}
	
	/**
	 * Shows a linestring representint the placeSearchItem and adds a popup at the center of the line
	 * @param placeSearchItem 
	 * @param map 
	 */
	private addSearchGeometryPopup(placeSearchItem: IPlaceSearchItem, location: L.LatLng, map: L.Map): void {
		const geojson: Proj4GeoJSONFeature = {
			type: 'Feature',
			geometry: {
				type: 'LineString',
				coordinates: placeSearchItem.geometry.map(
					x => { return [x.x, x.y]; })
			},
			properties: {

			},
			crs: {
				type: 'name',
				properties: {
					name: 'urn:ogc:def:crs:EPSG::3006'
				}
			}
		};

		const layer = L.Proj.geoJson(geojson, {
			style: function (feature: any) {
				return {
					color: '#ffff00',
					opacity: 0.8,
					weight: 10
				};
			}
		}).addTo(map);

		const popupContent = this.createPopupContainer(placeSearchItem);
		const popup = L.popup()
			.setContent(popupContent)

		map.once("popupclose", (e) => {
			layer.removeFrom(map);
		});

		layer.bindPopup(popup).openPopup();

		map.fitBounds(layer.getBounds());
	}
}
