import { autoinject, bindable, computedFrom } from 'aurelia-framework';

@autoinject()
export class ProgressIndicator {

    @bindable progress: IProgress;

    value: string;
    text: string;

    constructor() {
        
    }

    @computedFrom('isComplete')
    get color(): string {
        if (!this.isComplete) {
            return '';
        }

        if(this.isNullOrUndefined(this.progress.state)) {
            return '#a9c50e';
        }

        return this.progress.state === 'DONE' ? '#a9c50e' : '#E23F37';
    }

    @computedFrom('progress.state')
    get isStarted(): boolean {
        if (this.isNullOrUndefined(this.progress)) {
            return false;
        }

        if (this.isNullOrUndefined(this.progress.state)) {
            return !this.isNullOrUndefined(this.progress.progress);
        }

        return this.progress.state === 'IN_PROGRESS';
    }

    @computedFrom('progress.state')
    get isComplete(): boolean {
        if (this.isNullOrUndefined(this.progress)) {
            return false;
        }

        if (this.isNullOrUndefined(this.progress.state)) {
            if (this.isNullOrUndefined(this.progress.progress)) {
                return false;
            }

            return this.progress.progress === 1;
        }

        return this.progress.state === 'DONE' || this.progress.state === 'FAILED';
    }

    progressChanged() {
        if (!this.progress) {
            this.value = '';
            this.text = '';
            return;
        }

        if (!this.isNullOrUndefined(this.progress.progress)) {
            this.value = `${this.progress.progress * 100}%`;
        }

        if (!this.isNullOrUndefined(this.progress.progressText)) {
            this.text = this.getText();
        }
    }

    private getText(): string {
        const parts = this.progress.progressText.split("\n");
        return parts[parts.length - 1];
    }

    private isNullOrUndefined(value: any): boolean {
        return value === undefined || value === null;
    }
}

export interface IProgress {
    progress: number;
    progressText: string;
    state: 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE' | 'FAILED'
}