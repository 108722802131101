import { bindable } from 'aurelia-framework';

export class TabHeader {

	@bindable
	active: boolean;

	@bindable
	disabled: boolean;

	get className(): string {
		if (this.disabled) {
			return 'tab__tab-header tab__tab-header--disabled';
		}

		return this.active ? 'tab__tab-header tab__tab-header--selected' : 'tab__tab-header';
	}
}
