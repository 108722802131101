// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.number-badge-circle {
    position: absolute;
    right: -10px;
    top: -10px;
    
    width: 1.5em;
    height: 1.5em;
    box-sizing: initial;

    background: #F49F25;
    color: #fff;
    text-align: center;
    border-radius: 50%;    

    line-height: 1.5em;
    font-size: 1em;
    box-sizing: content-box;   
    border: 2px solid #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/common/number-badge/number-badge.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;;IAEV,YAAY;IACZ,aAAa;IACb,mBAAmB;;IAEnB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;;IAElB,kBAAkB;IAClB,cAAc;IACd,uBAAuB;IACvB,sBAAsB;AAC1B","sourcesContent":[".number-badge-circle {\r\n    position: absolute;\r\n    right: -10px;\r\n    top: -10px;\r\n    \r\n    width: 1.5em;\r\n    height: 1.5em;\r\n    box-sizing: initial;\r\n\r\n    background: #F49F25;\r\n    color: #fff;\r\n    text-align: center;\r\n    border-radius: 50%;    \r\n\r\n    line-height: 1.5em;\r\n    font-size: 1em;\r\n    box-sizing: content-box;   \r\n    border: 2px solid #fff;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
