// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../../../node_modules/select2/dist/css/select2.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/svg/button-arrow-grey-expanded.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select2-container .select2-selection--single {
    height: 34px !important;
    border-radius: 0px !important;
    width: 100% !important;
}

.select2-selection-default {
    border: 2px solid #777777 !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 32px !important;
    font-weight: bold !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 32px !important;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
    background-size: 15px 20px !important;
    background-repeat: no-repeat !important;
    background-position-y: 5px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none !important;
}
.select2-selection__placeholder {
    color: #999;
    opacity: 1; /* Firefox */
    font-weight: bold;
    font-style: italic;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    opacity: .8;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/select/select.css"],"names":[],"mappings":"AAEA;IACI,uBAAuB;IACvB,6BAA6B;IAC7B,sBAAsB;AAC1B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,4BAA4B;IAC5B,4BAA4B;AAChC;;AAEA;IACI,uBAAuB;IACvB,oEAA8F;IAC9F,qCAAqC;IACrC,uCAAuC;IACvC,qCAAqC;AACzC;;AAEA;IACI,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,UAAU,EAAE,YAAY;IACxB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf","sourcesContent":["@import '~select2';\r\n\r\n.select2-container .select2-selection--single {\r\n    height: 34px !important;\r\n    border-radius: 0px !important;\r\n    width: 100% !important;\r\n}\r\n\r\n.select2-selection-default {\r\n    border: 2px solid #777777 !important;\r\n}\r\n\r\n.select2-container--default .select2-selection--single .select2-selection__rendered {\r\n    line-height: 32px !important;\r\n    font-weight: bold !important;\r\n}\r\n\r\n.select2-container--default .select2-selection--single .select2-selection__arrow {\r\n    height: 32px !important;\r\n    background-image: url(../../../../assets/images/svg/button-arrow-grey-expanded.svg) !important;\r\n    background-size: 15px 20px !important;\r\n    background-repeat: no-repeat !important;\r\n    background-position-y: 5px !important;\r\n}\r\n\r\n.select2-container--default .select2-selection--single .select2-selection__arrow b {\r\n    display: none !important;\r\n}\r\n.select2-selection__placeholder {\r\n    color: #999;\r\n    opacity: 1; /* Firefox */\r\n    font-weight: bold;\r\n    font-style: italic;\r\n}\r\n\r\n.select2-container--default .select2-selection--single .select2-selection__clear {\r\n    cursor: pointer;\r\n    float: right;\r\n    font-weight: bold;\r\n    font-size: 24px;\r\n    line-height: 30px;\r\n    opacity: .8;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
