// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab {
    width: 100%;
    height: 100%;
}

.tab > div {
    background-color: #fff;
    position: absolute;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    min-width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/tabs/tab.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,8BAA8B;IAC9B,+BAA+B;IAC/B,gBAAgB;AACpB","sourcesContent":[".tab {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.tab > div {\r\n    background-color: #fff;\r\n    position: absolute;\r\n    border-bottom-left-radius: 5px;\r\n    border-bottom-right-radius: 5px;\r\n    min-width: 400px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
