import { bindable, children } from 'aurelia-framework';
import { Tab } from './tab';

export class Tabs {
    @bindable onTabSelected: Function;
    @bindable onTabDeactivated: Function;
    @bindable width = 100;

	@children('tab')
    tabs: Tab[];

    maxWidth: number;

	private firstCall = true;

	tabsChanged(): void {
		if (this.firstCall) {
			this.calculateMaxWidth();
			this.firstCall = false;
		}
	}

	select(tab: Tab): void {
		if (tab.disabled) {
			return;
		}

		const { name } = tab;

		let selected: Tab | undefined = undefined;

		for (let i = 0; i < this.tabs.length; i++) {
			if (this.tabs[i].name === name) {
				selected = this.tabs[i];
				break;
			}
		}

		if (!selected) {
			return;
		}

		selected.active = !selected.active;

        const tabsToDeactivate = [];
        if (!selected.active) {
            tabsToDeactivate.push(selected);
        }

		this.tabs
			.filter(tab => tab.name !== name)
			.forEach(deSelectedTab => {
                const isActive = deSelectedTab.active;
                deSelectedTab.active = false

                if (isActive) {
                    // add to deactivate list
                    tabsToDeactivate.push(deSelectedTab);
                }
            });

		if (this.onTabSelected) {
			this.onTabSelected(selected);
        }
        
        if (this.onTabDeactivated) {
            tabsToDeactivate.forEach(x => {
                this.onTabDeactivated(x);
            });
		}
	}

	private calculateMaxWidth(): void {
		// Calculate max-width for tabs
		this.maxWidth = this.width / (this.tabs.length - 1);
	}
}
