// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flatpickr-input.view-date {
    border-radius: 8px;
    border-style: none;
    background-color: #72b66b;
    color: #fff;
    padding: 5px;
    padding-left: 30px;
    margin-top: 10px;
    width: 120px;
}

.flatpickr-input.view-date.active {
    background-color: #fff;
    color: #444;
}

.flatpickr-input.view-date.passive:hover {
    background-color: #fff;
    color: #444;
}

.flatpickr-input.view-date.passive:hover ~ a .icon-filter-calendar.view-date:before {
    color: #444;
} 

.calendar-toggle {
    font-size: 36px;
    position: absolute;
    left: 6px;
    color: #fff;
}

.icon-filter-calendar.view-date {
    cursor: pointer;
}

.icon-filter-calendar.view-date:before {
    color: #fff;
}

.icon-filter-calendar.view-date.active:before {
    color: #444;
}`, "",{"version":3,"sources":["webpack://./src/components/common/view-date/view-date.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":[".flatpickr-input.view-date {\r\n    border-radius: 8px;\r\n    border-style: none;\r\n    background-color: #72b66b;\r\n    color: #fff;\r\n    padding: 5px;\r\n    padding-left: 30px;\r\n    margin-top: 10px;\r\n    width: 120px;\r\n}\r\n\r\n.flatpickr-input.view-date.active {\r\n    background-color: #fff;\r\n    color: #444;\r\n}\r\n\r\n.flatpickr-input.view-date.passive:hover {\r\n    background-color: #fff;\r\n    color: #444;\r\n}\r\n\r\n.flatpickr-input.view-date.passive:hover ~ a .icon-filter-calendar.view-date:before {\r\n    color: #444;\r\n} \r\n\r\n.calendar-toggle {\r\n    font-size: 36px;\r\n    position: absolute;\r\n    left: 6px;\r\n    color: #fff;\r\n}\r\n\r\n.icon-filter-calendar.view-date {\r\n    cursor: pointer;\r\n}\r\n\r\n.icon-filter-calendar.view-date:before {\r\n    color: #fff;\r\n}\r\n\r\n.icon-filter-calendar.view-date.active:before {\r\n    color: #444;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
