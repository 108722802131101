import { bindable, bindingMode, autoinject, customElement, BindingEngine, Disposable } from 'aurelia-framework';
import * as $ from 'jquery';
require('select2');

@customElement('vv-select')
@autoinject()
export class Select {

    @bindable placeholder = undefined;
    @bindable name = undefined;
    @bindable className = 'select2-selection-default';
    @bindable idProperty = undefined;
	@bindable presentationProperty = undefined;
	@bindable titleProperty = undefined;
    @bindable selectedProperty = undefined;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selected: any; // The default selected value
	@bindable options = [];
	@bindable optional = false;

    value: any;

    private subscription: Disposable;
	private select;

    constructor(private element: Element, private bindingEngine: BindingEngine) {

    }

    getPresentationValue(option: any): any {
        if (this.hasValue(option, this.presentationProperty)){
            return option[this.presentationProperty];
        }

        return option;
	}
	
	getTitleValue(option: any): any {
        if (this.hasValue(option, this.titleProperty)){
            return option[this.titleProperty];
        }

        return '';
    }

    getIdValue(option: any): any {
        if (this.hasValue(option, this.idProperty)){
            return option[this.idProperty];
        }

        return option;
    }

    attached() {
		const el = $(this.element).find('select');
		this.select = el.select2({
			placeholder: this.placeholder,
			allowClear: this.optional,
			minimumResultsForSearch: Infinity
		});

		this.select.val(this.getIdValue(this.selected)).trigger('change');

        this.value = `${this.getIdValue(this.selected)}`;

		this.select.on('change', (event) => {
			if (event.originalEvent) { 
				return; 
			}

			this.setSelected(event.target.value);
			this.value = `${this.getIdValue(this.selected)}`;

			const changeEvent = new CustomEvent('change', {
				detail: {
					value: event.target.value
				},
				bubbles: true
			});

			setTimeout(() => {
				this.element.dispatchEvent(changeEvent);
			}, 100);
        });

        this.setClass(this.className);

        this.subscription = this.bindingEngine
            .propertyObserver(this, 'className')
            .subscribe((newValue, oldValue) => { 
                this.setClass(newValue, oldValue) 
            });
    }

    detached() {
		this.subscription.dispose();
		const element = ($(this.element).find('select') as any);
		if (element) {
			element.select2().off('change');
			element.select2('destroy');
		}
    }

    selectedChanged() {
		if (!this.select) {
			return;
		}
    }

    private hasValue(option, propertyName): boolean {
        return propertyName && option && option.hasOwnProperty(propertyName);
    }

    private setSelected(id): void {
        let matches: any[] = undefined;

        if (this.idProperty) {
            matches = this.options.filter(x => x[this.idProperty] == id);
        }
        else {
            matches = this.options.filter(x => x == id);
        }

        if (matches && matches.length && matches.length > 0) {
            if (this.selectedProperty) {
                this.selected = matches[0][this.selectedProperty];
            }
            else {
                this.selected = matches[0];
            }
		}
		else {
			this.selected = undefined;
		}
    }

    private setClass(newValue, oldValue?) {
        const container = ($(this.element).find('.select2-selection') as any);

        if (oldValue) {
            container.removeClass(oldValue);
        }

        container.addClass(newValue);
    }
}