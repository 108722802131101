import { autoinject, bindable, computedFrom, bindingMode } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { IPlaceSearchItem } from 'services/place-search/models/place-search-item.interface';
import { PlaceSearchService } from 'services/place-search/place-search-service';

@autoinject()
export class NavBar {
	@bindable router: Router;
	@bindable todosBadgeValue: string;
	@bindable checkedoutBadgeValue: string;
	@bindable featureInfoBadgeValue: string;
	@bindable unvalidfeaturesBadgeValue:string;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) checkedOutTabIsActive;

	routeDifferencesTabIsActive:boolean;
	todosTabIsActive: boolean;
	value: IPlaceSearchItem;
	timeout = null;
	@computedFrom('router.currentInstruction')
	get isAdminLayout(): boolean {
		if (!this.router.currentInstruction) {
			return false;
		}

		return this.router.currentInstruction.config.name !== 'map';
	}

	constructor(private eventAggregator: EventAggregator, private searchService: PlaceSearchService) {

	}

	search = async (s: string): Promise<any> => {
		const h = await this.suggestions(s);
		console.log('Value:', h);
		return h;
	}

	suggestions(s: string): Promise<any> {
		// const insideTimeOut = this;
		const promise = new Promise(() => {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.searchService.getSuggestions(s)
			}, 1500);
		});

		return promise;
		// const promise = new Promise(function (resolve, reject) {
		// 	clearTimeout(insideTimeOut.timeout);
		// 	insideTimeOut.timeout = setTimeout(() => {
		// 		resolve(insideTimeOut.searchService.getSuggestions(s));
		// 	}, 1500);
		// });
		// return promise;
	}

	async itemSelected(event: CustomEvent) {
		const placeSearchItem = (event.detail as IPlaceSearchItem);

		this.eventAggregator.publish('map-events', {  type: 'zoomtolocation',
			location: { y: placeSearchItem.northing, x: placeSearchItem.easting},
			placeSearchItem: placeSearchItem
		});
	}

	formatItem = (searchItem: IPlaceSearchItem) => {
		return this.searchService.formatItem(searchItem);
	}

	tabSelected = (tab: any): void => {
		if (tab.name === "todos") {
			this.todosTabIsActive = tab.active;
			return;
		}
		if(tab.name === "RouteDifference"){
			this.routeDifferencesTabIsActive = tab.active;
		}
	}

	tabDeactivated = (tab: any): void => {
		if (tab.name === "todos") {
			this.todosTabIsActive = tab.active;
			return;
		}
		if(tab.name === "RouteDifference"){
			this.routeDifferencesTabIsActive = tab.active;
		}
	}
	
	clearSearch(){
		this.value = null;
	}
}
