import moment from 'moment';

export class IntDateFormatValueConverter {
    toView(value: number, emptyText?: string) {
        if (value === undefined || value === null) {
            return emptyText ? emptyText : '';
		}
		
        return moment(value.toString(), "YYYYMMDD").format("YYYY-MM-DD");
    }
}