import { autoinject } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { Router } from 'aurelia-router';

// import 'leaflet-draw';
import { IMapState, INote } from 'components/map/deviation-report/services/report-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { AppAuthService } from 'services/authentication/app-auth-service';
import { CustomMapControllers } from 'services/custom-map-controllers.service';
import { SearchPopup } from '../popups/search-popup';
import { StateService } from 'services/state-service';
import { NotePopup } from '../popups/note-popup';
import { SearchType } from 'services/place-search/models/search-type.enum';
import { Alert, AlertModel } from '../dialog/alert';
import { DomUtilExtension } from './dom-util-extensions/dom-util-extensions';
// declare const L: any;
import * as L from 'leaflet';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { LayerEventType } from 'vv-constants/layer-event-type.enum';
import { RouteEventItemType } from 'vv-constants/route-event-item-type.enum';
import { IRouteInformation } from 'services/application-repository/models/route-information.interface';
import { ILocationEvent } from 'services/application-repository/models/location-event.interface';
import { IMapRoutePoint } from 'services/application-repository/models/map-route-point.interface';
import { MapConfiguration } from 'services/map-configuration/map-configuration';
import { ExtendedPoint } from 'services/map-configuration/models/extended-point.interface';
require('leaflet-draw');

@autoinject()
export abstract class MapBase {
	public leafletMapEvents = ['click', 'load', 'moveend'];
	public showRoutePanel = false;

	protected layers;
	protected mapOptions;
	protected withScaleControl = { metric: true, imperial: false };
	protected withLayerControl = { metric: true };
	protected featureSelectedLoading: boolean;

	private mapstateNotes: INote[];
	private mapstateRoutes: IRouteInformation[];

	public map: L.Map;
	private isSetViewOperation = false;

	private propagateMapClicked = true;
	private leafletSubscription: Subscription;
	private mapEventsSubscription: Subscription;
	private alertErrorSubscription: Subscription;
	private mapClickEnabledSubscription: Subscription;
	private layerViewDateChangedSubscription: Subscription;

	constructor(
		private eventAggregator: EventAggregator,
		private mapConfig: MapConfiguration,
		private appAuthService: AppAuthService,
		private dialogService: DialogService,
		private customMapControllers: CustomMapControllers,
		private searchPopup: SearchPopup,
		public applicationRepo: ApplicationRepository,
		private router: Router,
		isAdmin: boolean,
		private stateService: StateService = null, 
		private notePopup: NotePopup = null) {
			this.setup(isAdmin);
			DomUtilExtension
	}

	async activate(params) {
		if (params && params.data) {
			const parts = params.data.split(';');
			for (let i = 0; i < parts.length; i++) {
				if (parts[i].startsWith('@')) {
					const locParts = parts[i].substr(1).split(',');

					if (locParts.length === 1) {
						// Guid for saved map state
						const mapState = await this.stateService.getState<IMapState>(locParts[0]);
						this.mapstateRoutes = mapState.routesInformation

						const position = this.mapOptions.crs.unproject(new L.Point(mapState.x, mapState.y));
						if (this.map == null) {
							this.mapOptions.center.lat = position.lat;
							this.mapOptions.center.lng = position.lng;
							this.mapOptions.zoomLevel = mapState.zoom;
							this.mapstateNotes = mapState.notes;
						}
						else {
							this.isSetViewOperation = true;
							this.map.setView([position.lat, position.lng], mapState.zoom);

							this.eventAggregator.publish(RouteEventItemType.CREATE_MAPSTATE_ROUTES, this.mapstateRoutes);
							this.notePopup.setNotePopups(this.map, mapState.notes);
						}
					}
					else if (locParts.length === 3) {
						const position = this.mapOptions.crs.unproject(new L.Point(+locParts[0], +locParts[1]));

						if (this.map == null) {
							this.mapOptions.center.lat = position.lat;
							this.mapOptions.center.lng = position.lng;
							this.mapOptions.zoomLevel = +locParts[2];
						}
						else {
							this.isSetViewOperation = true;
							this.map.setView([position.lat, position.lng], +locParts[2]);
						}
					}
				}
			}
		}
	}

	deactivate() {
		this.leafletSubscription.dispose();
		this.mapEventsSubscription.dispose();
		this.alertErrorSubscription.dispose();
		this.mapClickEnabledSubscription.dispose();
		this.layerViewDateChangedSubscription.dispose();
		
		this.applicationRepo.map = undefined;
	}

	setRouteStartLocation = (location: ExtendedPoint) => {
		const startPoint = {
			id: "",
			name: Math.round(location.projected.x) + ', ' + Math.round(location.projected.y),
			type: SearchType.COORDINATE,
			latlng: location.latlng,
			projected: location.projected,
			easting: location.projected.x,
			northing: location.projected.y
		};
		const locationEvent = startPoint as ILocationEvent;
		locationEvent.locationType = "START";
		this.eventAggregator.publish(MapEventType.MAP_LOCATION_SELECTED, locationEvent);
	}

	setRouteEndLocation = (location: ExtendedPoint) => {
		const endPoint = {
			id: "",
			name: Math.round(location.projected.x) + ', ' + Math.round(location.projected.y),
			type: SearchType.COORDINATE,
			latlng: location.latlng,
			projected: location.projected,
			easting: location.projected.x,
			northing: location.projected.y
		};
		const locationEvent = endPoint as ILocationEvent;
		locationEvent.locationType = "END";
		this.eventAggregator.publish(MapEventType.MAP_LOCATION_SELECTED, locationEvent);
	}

	setRouteViaInMap = (location: ExtendedPoint) => {
		if (!this.applicationRepo.canAddViaPoint()) {
			return;
		}

		const viaPoint: IMapRoutePoint = {
			id: "",
			name: Math.round(location.projected.x) + ', ' + Math.round(location.projected.y),
			type: SearchType.COORDINATE,
			latlng: location.latlng,
			projected: location.projected,
			easting: location.projected.x,
			northing: location.projected.y
		};
		const locationEvent = viaPoint as ILocationEvent;
		locationEvent.locationType = "VIA";
		this.eventAggregator.publish(MapEventType.MAP_LOCATION_SELECTED, locationEvent);
	}

	setDynamicBlockCoordInMap = (location: ExtendedPoint): void => {
		if (!this.applicationRepo.canAddDynamicBlocks()) {
			return;
		}

		const dynamicPoint: IMapRoutePoint = {
			id: "",
			name: Math.round(location.projected.x) + ', ' + Math.round(location.projected.y),
			type: SearchType.COORDINATE,
			latlng: location.latlng,
			projected: location.projected,
			easting: location.projected.x,
			northing: location.projected.y
		};
		const locationEvent = dynamicPoint as ILocationEvent;
		locationEvent.locationType = "COORDBLOCK";
		this.eventAggregator.publish(MapEventType.MAP_LOCATION_SELECTED, locationEvent);
	}

	setDynamicBlockPolygonInMap = (): void => {
		if(!this.applicationRepo.canAddDynamicBlocksPolygon()){
			return;
		}
		
		this.eventAggregator.publish("POLYGONEVENT");

	}


	private setup(isAdmin: boolean) {
		this.leafletSubscription = this.eventAggregator.subscribe('aurelia-leaflet', (payload) => {
			
			if (payload.type === 'load' && payload.map !== null) {
				this.applicationRepo.map = payload.map;
				this.map = this.applicationRepo.map;
				this.customMapControllers.addCustomControls(this.map);

				// Show coordinate in context menu and activate/deactivate routeFrom, routeVia, routeTo
				this.map.on('contextmenu', (e: any) => {
					const contextmenu = e.contextmenu;

					let disabled = true;
					if (this.applicationRepo.canAddLocations() && this.applicationRepo.canAddViaPoint()) {
						disabled = false;
					}

					const startIndex = isAdmin ? 4 : 5;
					contextmenu.setDisabled(startIndex, !this.applicationRepo.canAddLocations());
					contextmenu.setDisabled(startIndex + 1, disabled);
					contextmenu.setDisabled(startIndex + 2, !this.applicationRepo.canAddLocations());
					contextmenu.setDisabled(startIndex + 4, !this.applicationRepo.canAddDynamicBlocks());
					contextmenu.setDisabled(startIndex + 5, !this.applicationRepo.canAddDynamicBlocksPolygon());

					const point = this.mapOptions.crs.project(e.latlng);
					contextmenu.removeItem(0);
					contextmenu.insertItem({
						text: '<i class="fa fa-location-arrow" aria-hidden="true"></i>'
							+ 'X:' + point.x.toFixed(0) + ', Y:' + point.y.toFixed(0),
						disabled: true
					}, 0);
				});

				if (!isAdmin) {
					this.notePopup.configure(this.map);
					// Check if we should show saved map state
					if (this.mapstateRoutes) {
						this.eventAggregator.publish(RouteEventItemType.CREATE_MAPSTATE_ROUTES, this.mapstateRoutes);
					}

					if (this.mapstateNotes) {
						this.notePopup.setNotePopups(this.map, this.mapstateNotes);
					}
				}

				this.map.eachLayer((layer: L.Layer) => {
					if (typeof (layer as any).setParams !== "undefined") {
						// Vector layers does not have this function
						const token =  this.appAuthService.getPrincipal().mapToken;
						(<any>layer).setParams({token: token});
					}
				});
				
				this.eventAggregator.publish(MapEventType.MAP_LOADED);
			}
			else if (payload.type === 'moveend' && this.map) {

				if (!this.isSetViewOperation) {
					const center = payload.map.getCenter();
					const crs = this.mapConfig.getMapCrs();
					const point = crs.crs.project(new L.LatLng(center.lat, center.lng));
					const currentRouteName = this.router.currentInstruction.config.name;
					const nextState = `#/${currentRouteName}/@${point.x.toFixed(0)},${point.y.toFixed(0)},${payload.map.getZoom()}`;

					if (nextState !== history.state) {
						history.pushState(nextState, '', nextState);
					}
				}

				this.isSetViewOperation = false;
				this.eventAggregator.publish(MapEventType.MAP_MOVED);
			}
			else if (payload.type === 'click') {
				if (this.propagateMapClicked) {
					this.eventAggregator.publish(MapEventType.MAP_CLICKED, payload.latlng);
				}
			}
		});

		this.mapEventsSubscription = this.eventAggregator.subscribe('map-events', (payload) => {
			if (payload.type === 'zoomtolocation') {
				this.searchPopup.addSearchPopup(this.map, payload);
			}
			else if (payload.type === 'panto') {
				const geometry = [];
				if (payload.CRS && payload.CRS === 'EPSG:3006') {
					payload.geometry.forEach(geom => {
						geometry.push(this.mapOptions.crs.unproject(geom));
					});
				}

				const bounds = new L.LatLngBounds(geometry);
				this.map.fitBounds(bounds);
			}
		});

		this.alertErrorSubscription = this.eventAggregator.subscribe('alertError', ErrorResponse => {

			const alertModel: AlertModel = {
				Mode: "info",
				ErrorMessage: ErrorResponse.ErrorMessage,
				ErrorCode: ErrorResponse.ErrorCode,
			};

			this.dialogService.open({ viewModel: Alert, model: alertModel });
		});

		this.mapClickEnabledSubscription = this.eventAggregator.subscribe(MapEventType.MAP_CLICK_ENABLED, enabled => {
			this.propagateMapClicked = enabled;
		});

		this.layerViewDateChangedSubscription = this.eventAggregator.subscribe(LayerEventType.VIEW_DATE_CHANGED, (viewDate) => {
			this.map.eachLayer((layer: L.Layer) => {
				if (typeof (layer as any).setParams !== "undefined") {
					// Vector layers does not have this function
					(<any>layer).setParams({ P_SEARCHDATE: viewDate });
				}
			});
		});

		this.layerViewDateChangedSubscription = this.eventAggregator.subscribe(LayerEventType.INVALIDATE_CACHE, () => {
			const timeVersion = new Date().getTime();
			this.map.eachLayer((layer: L.Layer) => {
				if (typeof (layer as any).setParams !== "undefined") {
					// Vector layers does not have this function
					(<any>layer).setParams({ CACHE_VERSION: timeVersion });
				}
			});
		});

	}
}