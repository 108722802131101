import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import flatpickr from "flatpickr";
import { Swedish } from "./sv"
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { LayerEventType } from 'vv-constants/layer-event-type.enum';

@autoinject()
export class ViewDate {

    protected viewDate: string;

    private dateFormat = 'Y-m-d';
    private picker: flatpickr.Instance;
    private isOpen = false;
    private closeTimestamp: number;

    constructor(
        private element: Element, 
        private eventAggregator: EventAggregator,
        private applicationRepository: ApplicationRepository
    ) {
        const now: Date = new Date();
		const paddedMonth = ("0" + (now.getMonth() + 1)).slice(-2)
		const paddedDate = ("0" + now.getDate()).slice(-2);

        this.viewDate =  `${now.getFullYear()}-${paddedMonth}-${paddedDate}`;
        this.applicationRepository.viewDate = this.formatDateString(this.viewDate);
    }

    attached(): void {
        const input = this.element.querySelector('input');
        this.picker = (flatpickr as any)(input, {
            defaultDate:  new Date(),
            dateFormat: this.dateFormat,
            enableTime: false,
            locale: Swedish,
            // wrap: true,
            onChange: (args) => {
                const date = new Date(args);
                this.viewDate = this.picker.formatDate(date, this.dateFormat);
                this.viewDateChanged();                
            },
            onOpen: () => {
                this.isOpen = true;
            },
            onClose: () => {
                this.isOpen = false;
                this.closeTimestamp = new Date().getTime();
            }
        });
    }
    
    detached() {
        if (this.picker) {
            this.picker.destroy();
        }
    }

    protected toggle(): void {
        const initial = this.isOpen;

        const insideTolerance = () => {
            if (!this.closeTimestamp) {
                return true;
            }

            const now = new Date().getTime();
            return (now - this.closeTimestamp) > 500;
        };

        setTimeout(() => {
            if (initial === this.isOpen && insideTolerance()) {
                this.picker.toggle();
            }

            this.closeTimestamp = undefined;
        }, 50);
    }

    private viewDateChanged(): void {
        this.applicationRepository.viewDate = this.formatDateString(this.viewDate);
        this.eventAggregator.publish(LayerEventType.VIEW_DATE_CHANGED, this.formatDateString(this.viewDate));
	}

    private formatDateString(date: string): string {
		const stringYear = date.substr(0, 4);
		const stringMonth = date.substr(5, 2);
		const stringDay = date.substr(8, 2);

		return stringYear + stringMonth + stringDay;
    }
}
