import { autoinject } from 'aurelia-framework';
import * as L from 'leaflet';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { MapConfiguration } from 'services/map-configuration/map-configuration';
import { INote } from 'components/map/deviation-report/services/report-service';
import { IconService } from 'services/assets/services/icon.service';
import { IconType } from 'services/assets/enums/icon-type.enum';


@autoinject()
export class NotePopup {

	private crs: L.Proj.CRS;
	private noteMarkers: L.Marker[] = [];

	constructor(
		private mapConfiguration: MapConfiguration,
		private applicationRepository: ApplicationRepository,
		private iconService: IconService) {

		this.crs = this.mapConfiguration.getMapCrs().crs;
	}

	public configure(map: L.Map) {
		this.setContextMenuListener(this.applicationRepository.map);
	}

	public getNotePopups(): INote[]{
		const ret: INote[] = [];

		let marker: L.Marker;
		for (let i = 0; i < this.noteMarkers.length; i++) {
			marker = this.noteMarkers[i];

			const latlng = (marker as any)._latlng;
			const point = this.crs.project(latlng);

			const popup = marker.getPopup();
			const container: HTMLDivElement = (popup as any)._container;

			const textArea = container.querySelector("textarea") as HTMLTextAreaElement;

			const item: INote = {
				latlng: latlng,
				point: point,
				text: textArea.value
			};

			ret.push(item);
		}

		return ret;
	}

	public setNotePopups(map: L.Map, notes:INote[]): void {

		let note: INote;
		for (let i = 0; i < notes.length; i++) {
			note = notes[i];
			this.createNotePopupMarker(map, note);
		}
	}

	private createNotePopupMarker(map: L.Map, note: INote): void {
		const contatiner = L.DomUtil.create('div', 'postit');
		
		const closeBtn = L.DomUtil.create('span', 'close-postit');
		closeBtn.innerText = '-';
		closeBtn.title = 'Fäll ihop notering';
		closeBtn.onclick = () => {
			marker.closePopup();
		};

		const label = L.DomUtil.create('label', 'label-postit pull-left');
		label.innerText = 'X: ' + note.point.x.toFixed(0) + ', Y: ' + note.point.y.toFixed(0);

		const textArea = L.DomUtil.create('textarea', 'textarea-postit');
		textArea.innerHTML = note.text;

		const pTag = L.DomUtil.create('p');
		const deleteBtn = L.DomUtil.create('button', 'btn vv-btn') as HTMLButtonElement;
		deleteBtn.style.height = "2em";
		deleteBtn.style.lineHeight = "1em";
		deleteBtn.style.backgroundColor = "#E23F37";
		deleteBtn.style.color = "#fff"
		deleteBtn.textContent = "Ta bort";
		deleteBtn.onclick = () => {

			let spliceIndex = -1;
			for(let i = 0; i < this.noteMarkers.length; i++) {
				if ((this.noteMarkers[i] as any)._leaflet_id === (marker as any)._leaflet_id) {
					spliceIndex = i;
					break;
				}
			}
			
			if (spliceIndex > -1) {
				this.noteMarkers.splice(spliceIndex, 1);
			}

			map.removeLayer(marker);
		};

		contatiner.appendChild(closeBtn);
		contatiner.appendChild(label);
		contatiner.appendChild(textArea);
		contatiner.appendChild(deleteBtn);

		const popup = L.popup({
			maxHeight: 360,
			maxWidth: 360,
			minWidth: 0,
			autoPan: false,
			closeOnClick: false,
			autoClose: false,
			closeButton: false
		})
		.setContent(contatiner);

		const icon = L.icon({
			iconUrl: this.iconService.getIcon(IconType.IconNotering),
			iconSize: [28, 30],
			iconAnchor: [14, 30]
		});

		const marker = L.marker(note.latlng, { icon: icon, title: 'Visa notering' });
		this.noteMarkers.push(marker);
		map.addLayer(marker);

		marker.bindPopup(popup).openPopup();

		return;
	}

	private setContextMenuListener(map: L.Map) {

		map.on('contextmenu', (e: any) => {
			const contextmenu: any = e.contextmenu;
			
			const point = this.crs.project(e.latlng);
			contextmenu.removeItem(3);
			contextmenu.insertItem({
				text: '<i class="fa fa-sticky-note-o" aria-hidden="true"></i>Notering',
				callback: () => {
					const note: INote = {
						latlng: e.latlng,
						point: point,
						text: ""
					}
					this.createNotePopupMarker(map, note);
				}
			}, 3);


		});
	}
}
