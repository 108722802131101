import { bindable, bindingMode } from 'aurelia-framework';

export class FeatureInfoTab {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) badgeValue: string;

    expanded: boolean;

    toggle(): void {
        this.expanded = !this.expanded;
    }
}