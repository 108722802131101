import { bindable, computedFrom, bindingMode } from 'aurelia-framework';

export class Tab {

	@bindable({ defaultBindingMode: bindingMode.twoWay }) 
	active: boolean;

	@bindable
	label: string;

	@bindable
	name: string;

	@bindable
	title: string;
	
	@bindable
	icon: string;

	@bindable
	disabled: boolean = false;

	@bindable badgeValue: string;

	@computedFrom('badgeValue')
	get showBadge(): boolean {
		return !(!this.badgeValue);
	}
	
	@computedFrom('icon')
	get hasIcon(): boolean {
		return !(!this.icon);
	}
	
	@computedFrom('label')
	get hasLabel(): boolean {
		return !(!this.label);
	}
}
