import { autoinject } from 'aurelia-framework';
import * as L from 'leaflet';
import { ICoordinateBlock } from 'services/data-layer/models/blocks/coordinate-block.interface';
import { IDynamicBlock } from 'services/data-layer/models/blocks/dynamic-block.interface';
import { ILinkBlock } from 'services/data-layer/models/blocks/link-block.interface';
import { INvdbBlock } from 'services/data-layer/models/blocks/nvdb-block.interface';


@autoinject()
export class BlockagePopup {

    public getBlockagePopup(blockage: IDynamicBlock) : L.Popup {
		const container = this.createPopupContainer(blockage);
		return L.popup().setContent(container);
    }
    
    /**
	 * Creates popup content for DynamicBlock
	 * @param payload 
	 */
	private createPopupContainer(blockage: IDynamicBlock): HTMLElement {
		const contatiner = L.DomUtil.create('div', 'search-popup leaflet-contextmenu');
		contatiner.style.display = 'block';
		
		const innerContainer = L.DomUtil.create('div', 'blockage-popup-container', contatiner);
		innerContainer.style.padding = '0 12px';

        // Append generic information
		const blockageType = L.DomUtil.create('h5', '', innerContainer);
		blockageType.innerText = `Blockeringstyp: ${this.getBlockageTypeString(blockage)}`;

		const id = L.DomUtil.create('h5', '', innerContainer);
		id.innerText = `Id: ${blockage.Id || "-"}`;

		const comment = L.DomUtil.create('h5', '', innerContainer);
		comment.innerText = `Kommentar: ${blockage.Comment || "-"}`;

		const delay = L.DomUtil.create('h5', '', innerContainer);
		delay.innerText = `Fördröjning: ${blockage.Delay || "-"}`;

		const heading = L.DomUtil.create('h5', '', innerContainer);
		heading.innerText = `Riktning: ${String(blockage.Heading) || "-"}`;

		const validFrom = L.DomUtil.create('h5', '', innerContainer);
		validFrom.innerText = `Gäller fr.o.m. ${blockage.ValidFrom || "-"}`;

		const validToType = L.DomUtil.create('h5', '', innerContainer);
		validToType.innerText = `Gäller t.o.m. ${blockage.ValidTo || "-"}`;


        // Append specific information for blockage type
		this.appendSpecifics(innerContainer, blockage);

		return contatiner;
	}

	private getBlockageTypeString(blockage: IDynamicBlock): string {
		switch (blockage.BlockageTypeString) {
			case "Nvdb":
				return "NVDB";
			case "Link":
				return "Länk";
			case "Coord":
				return "Koordinat";
			case "Polygon":
				return "Polygon";
			default:
				throw new Error("Invalid BlockageTypeString provided");
		}
	}

	private appendSpecifics(container: HTMLElement, blockage: IDynamicBlock): void {
		if (blockage.BlockageTypeString === "Nvdb") {
			const routeId = L.DomUtil.create('h5', '', container);
			routeId.innerText = `RouteId: ${(blockage as INvdbBlock).RouteId || "-"}`;
	
			const measure = L.DomUtil.create('h5', '', container);
			measure.innerText = `Measure: ${(blockage as INvdbBlock).Measure || "-"}`;
		}
		else if (blockage.BlockageTypeString === "Link") {
			const linkId = L.DomUtil.create('h5', '', container);
			linkId.innerText = `LinkId: ${(blockage as ILinkBlock).LinkId || "-"}`;
		}
		else if (blockage.BlockageTypeString === "Coord") {
			const radius = L.DomUtil.create('h5', '', container);
			radius.innerText = `Radie: ${(blockage as ICoordinateBlock).Radius || "-"}`;

			const point = blockage.Geometry[0][0];
			const coordX = L.DomUtil.create('h5', '', container);
			coordX.innerText = `X: ${point.X.toFixed(0)}`;

			const coordY = L.DomUtil.create('h5', '', container);
			coordY.innerText = `Y: ${point.Y.toFixed(0)}`;
		}

		return;
	}
}