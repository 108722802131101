import moment from 'moment';

export class DateFormatValueConverter {
    toView(value: number, format:string, emptyText?: string) {
        if (value === undefined || value === null) {
            return emptyText ? emptyText : '';
		}
		
        return moment(value).format(format);
    }
}