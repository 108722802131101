"use strict";

import L from "leaflet";

export const DomUtilExtension = ((): void => {
    /**
     * Overrides default leaflet implementation by wrapping x/y in Math.round
     */
    window.L.DomUtil.setTransform = (el, offset, scale): void => {
        const pos = offset || new L.Point(0, 0);
    
        el.style[L.DomUtil.TRANSFORM] =
            (L.Browser.ie3d ?
                'translate(' + pos.x + 'px,' + pos.y + 'px)' :
                'translate3d(' + Math.round(pos.x)+ 'px,' + Math.round(pos.y) + 'px,0)') +
            (scale ? ' scale(' + scale + ')' : '');
    }

    /**
     *  Overrides default leaflet implementation by wrapping x/y in Math.round
     */
    window.L.DomUtil.setPosition = (el, point): void =>  {
        /*eslint-disable */
        (el as any)._leaflet_pos = point;
        /*eslint-enable */
    
        if (L.Browser.any3d) {
            L.DomUtil.setTransform(el, point);
        } else {
            el.style.left = Math.round(point.x) + 'px';
            el.style.top = Math.round(point.y) + 'px';
        }
    }
})();
