import { autoinject, bindable, computedFrom, LogManager, Disposable, TaskQueue } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router, NavModel } from 'aurelia-router';
import { Logger } from 'aurelia-logging';
import { DialogService } from "aurelia-dialog";
import moment from 'moment';
import * as L from 'leaflet';
import { VersionTypeMap } from 'components/admin/admin-constants/index';
import { Alert, AlertModel } from 'components/common/dialog/alert';
import { AppRole } from 'services/authentication/models/app-roles.enum';
import { AppAuthService } from 'services/authentication/app-auth-service';
import { VersionsService } from 'services/version/versions-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { StateService } from 'services/state-service';
import { RoutingService } from 'services/routing/routing-service';
import { IJobInfoData, JobStatus, ProgressCallback, TneAsyncResolver } from 'components/admin/services/tne-async-resolver';
import { RouteDifferenceService } from 'components/admin/services/route-difference-service';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';
import { IPrincipal } from 'services/authentication/models/principal.interface';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { Version } from 'services/version/models/version.model';

@autoinject()
export class SideNav {
    @bindable router: Router;

    expanded = false;
    loading: boolean;
	jobProgress: any;

	protected routeDatasetChecked = false;
	protected testRouteDataset: IRouteDataset;

    private exportDiagonalThreshold = 80000;
    private logger: Logger;
	private principal: IPrincipal;
	private versions: Version[];
	private adminRoles = [AppRole.VV_EDITOR, AppRole.VV_COMMITTER];
	private map: L.Map;
	private mapLoadedSubscription: Disposable;
	private sourceLayer: L.Rectangle;
	private editLayer: L.Rectangle;
	private lastUpdate: string;

	@computedFrom('principal', 'principal.roles')
	get isAdminLayout(): boolean {
		if (!this.principal) {
			return false;
		}

		return this.principal.someRoles(this.adminRoles);
	}

    @computedFrom('expanded')
    get width(): string {
        if (this.expanded) {
            return '320';
        }

        return '0';
	}
	
	@computedFrom('testRouteDataset')
	get hasTestRouteDataset(): boolean {
		return this.testRouteDataset ? true : false;
	}

	@computedFrom('editLayer')
	get isEditing(): boolean {
		return this.editLayer ? true : false;
	}

	@computedFrom('testRouteDataset', 'map')
	get datasetCenter(): string {
		if (!this.testRouteDataset || !this.map){
			return "";
		}

		const mapCrs = this.appRepo.mapCrs;
		const southWest = mapCrs.unproject(this.testRouteDataset.bbox.southWest as any);
		const northEast = mapCrs.unproject(this.testRouteDataset.bbox.northEast as any);

		const bbox = L.latLngBounds(southWest, northEast);
		const center = this.appRepo.mapCrs.project(bbox.getCenter());

		return `${Math.round(center.x)}, ${Math.round(center.y)}`;
	}
	@computedFrom('principal')
	get isCommitter(): boolean {
		if (!this.principal) {
			return false;
		}
		return this.principal.isInRole(AppRole.VV_COMMITTER)
	}


	constructor(
		private eventAggregator: EventAggregator,
        private appAuthService: AppAuthService,
        private appRepo: ApplicationRepository,
        private stateService: StateService, 
        private routingService: RoutingService,
        private dialogService: DialogService,
		private resolver: TneAsyncResolver,
		private versionService: VersionsService,
		private differenceService: RouteDifferenceService,
		private config: EnvironmentConfiguration) {

        this.logger = LogManager.getLogger('SideNav');
	}
	
	async bind() {
		this.loading = true;
		const data = await this.stateService.getState('TEST_ROUTE_VVDL') as IRouteDataset;
		if (data) {
			this.testRouteDataset = data;
			this.createSourceLayer();
		}
		this.loading = false;
	}

    attached(): void {
		this.principal = this.appAuthService.getPrincipal();
		this.setVersions();
		this.setLastUpdateVvdl();
		this.jobProgress = undefined;

		this.map = this.appRepo.map;
		if (this.map) {
			this.createSourceLayer();
		}
		else {
			this.mapLoadedSubscription = this.eventAggregator.subscribeOnce(MapEventType.MAP_LOADED, () => {
				this.map = this.appRepo.map;

				if (!this.map) {
					throw new Error("Could not get hold of map");
				}

				this.createSourceLayer();
			});
		}
    }

    detached(): void {
		this.jobProgress = undefined;

		if (this.mapLoadedSubscription) {
			this.mapLoadedSubscription.dispose();
			this.mapLoadedSubscription = undefined;
		}
    }

    toggle(): void {
        this.expanded = !this.expanded;
    }
    
    login(): void {
		this.appAuthService.login();
	}

	logout(): void {
		this.appAuthService.logout();
    }
    
    goTo(nav: NavModel): void {
        
		const hash = window.location.hash;
		const parts = hash.split('@');
		
		let params = null;
		if (parts && parts.length === 2) {
			params = { data: `@${parts[1]}` };
		}

		this.expanded = false;
		this.abortCreateRouteDataset();
		if (this.routeDatasetChecked) {
			this.routeDatasetChecked = false;
			this.map.removeLayer(this.sourceLayer);
		}
		this.router.navigateToRoute(nav.config.name, params, { }); // TODO Check functionality. { force: true }

		//timeout to update the map object after switching between admin and map
		setTimeout(() => {
			this.map = this.appRepo.map;
			if (this.map) {
				this.createSourceLayer();
			}
        }, 1000);
		
	}
	
	async setVersions(){

		try{
			this.versions = await this.versionService.getVersions();
			this.versions.push(new Version('KartklientVersion', this.config.env.Version));
		}
		catch (error){
			this.versions = [];
			this.versions.push(
				new Version('TREVersion', "Gick inte att hämta"),
				new Version('TNE2NetVersion', "Gick inte att hämta"),
				new Version('DistanceServiceVersion', "Gick inte att hämta"),
				new Version('KVVResistanceVersion', "Gick inte att hämta"),
				new Version('KartklientVersion', this.config.env.Version));
		}

	}

	async setLastUpdateVvdl(){

		try{
			const jobdefid = this.config.env.JobDefinitionIdProdkv;
			const jobData = await this.resolver.executeGetJobInfo(jobdefid);
			this.lastUpdate = jobData.LastUpdated;
		}
		catch (error){
			this.lastUpdate = "Gick inte att hämta";
		}

	}

	beginCreateRouteDataset(): void {
		if (this.editLayer) {
			this.map.removeLayer(this.editLayer);
		}

		this.createNewLayer();
		this.map.addLayer(this.editLayer);
	}

	abortCreateRouteDataset(): void {
		if (this.editLayer) {
			this.map.removeLayer(this.editLayer);
			this.editLayer = undefined;
		}
	}

    async createRouteDataset(): Promise<void> {
        this.jobProgress = undefined;

		const bounds = this.editLayer.getBounds()
        const southWest = this.appRepo.mapCrs.project(bounds.getSouthWest());
        const northEast = this.appRepo.mapCrs.project(bounds.getNorthEast());

        const okToProceed = await this.confirmBoundingBox(southWest, northEast);

        if (!okToProceed) {
            return;
        }

        try {
            this.loading = true;

			this.jobProgress = {
				progress: 0,
				progressText: "Skapar jobb för nytt datalager...",
				state: 'IN_PROGRESS'
			};

            const jobDefinitionId = await this.routingService.createTestRoutingDataLayer(southWest.x, southWest.y, northEast.x, northEast.y);

			let progressText: string = undefined;
            const jobProgressCallback: ProgressCallback = (res: IJobInfoData) => {
                if (res.ProgressText !== progressText) {
                    progressText = res.ProgressText;
                    this.jobProgress = {
                        progress: res.Progress,
                        progressText: res.ProgressText,
                        state: 'IN_PROGRESS'
                    };
                }
            }

            const jobData = await this.resolver.execute(jobDefinitionId, jobProgressCallback, 2000);
    
            if (jobData.Status === JobStatus.Completed) {
                
                const toSave = {
                    userName: this.principal.userName,
                    bbox: { southWest: southWest, northEast: northEast },
                    savedAt: new Date().toISOString()
                }

                this.jobProgress.progressText = 'Sparar inställningar';

                await this.stateService.putState('TEST_ROUTE_VVDL', toSave);

				const data = await this.stateService.getState('TEST_ROUTE_VVDL') as IRouteDataset;
				if (data) {
					this.map.removeLayer(this.editLayer);
					this.editLayer = undefined;
					this.map.removeLayer(this.sourceLayer);
					this.sourceLayer = undefined;

					this.testRouteDataset = data;
					this.createSourceLayer();
				}

                this.jobProgress = {
                    progress: 1,
                    progressText: 'Inställningar sparade',
                    state: 'DONE'
                };
            }
            else {
                this.jobProgress.state = 'FAILED';
            }
        }
        catch (error) {
            this.logger.error(error);
            this.jobProgress.state = 'FAILED';
        }

        this.loading = false;
        setTimeout(() => {
            this.jobProgress = undefined;
        }, 3000);
    }

	routeDatasetCheckedChanged(): void {
		if (!this.sourceLayer) {
			return;
		}

		if (this.routeDatasetChecked) {
			this.map.addLayer(this.sourceLayer);
		}
		else {
			this.map.removeLayer(this.sourceLayer);
		}
	}

	private createSourceLayer() {
		if (this.map && this.testRouteDataset && Object.keys(this.testRouteDataset).length > 0 && !this.sourceLayer) {
			const mapCrs = this.appRepo.mapCrs;

			const southWest = mapCrs.unproject(this.testRouteDataset.bbox.southWest as any);
			const northEast = mapCrs.unproject(this.testRouteDataset.bbox.northEast as any);
			this.sourceLayer = L.rectangle([
				southWest as any, 
				northEast as any
			]);

			if (this.routeDatasetChecked) {
				this.map.addLayer(this.sourceLayer);
			}
		}
	}

	private createNewLayer() {
		if (this.map) {
			const mapBounds = this.map.getBounds().pad(-(Math.sqrt(2) / 2));
			this.editLayer = L.rectangle(mapBounds);
			(this.editLayer as any).editing.enable();
		}
	}

    private async confirmBoundingBox(southWest: any, northEast: any): Promise<boolean> {
        if (this.boundsAreWithinThreshold(southWest, northEast)) {
            return true;
        }

        const promise = new Promise<boolean>((resolve, reject) => {
            const alertModel: AlertModel = {
				Mode: "question",
				ErrorMessage: "Ytan för området du är på väg att skapa dataset för är större än rekommenderat, vill du fortsätta?",
                AbortText: "Avbryt",
                ConfirmText: "Skapa dataset",
				ConfirmButtonColor: "red"
            };
    
            this.dialogService.open({viewModel: Alert, model: alertModel}).whenClosed(response => {
                return resolve(!response.wasCancelled);
            });
        });

        return promise;
	}
	
	private boundsAreWithinThreshold(southWest: any, northEast: any): boolean {
		const dX = northEast.x - southWest.x;
        const dY = northEast.y - southWest.y;

        const dC = Math.sqrt(Math.pow(dX, 2) + Math.pow(dY, 2));

		return dC < this.exportDiagonalThreshold;
	}

	vvdlToProductionDialog(){
		if (!this.isCommitter) {
			const notAuthModel: AlertModel = {
				Mode: "error",
				ErrorMessage: "Du saknar behörighet för att lyfta vvdl-filen till produktion"
			};

			this.dialogService.open({viewModel: Alert, model: notAuthModel});
			return
		}

		const alertModel: AlertModel = {
			Mode: "question",
			ErrorMessage: "Vill du lyfta vvdl-filen till produktion?",
			AbortText: "Nej",
			ConfirmText: "Ja"
		};

		this.dialogService.open({viewModel: Alert, model: alertModel}).whenClosed(response => {
			if (!response.wasCancelled) {
				this.startTREControlJob();
			}
		});
	}
	private async startTREControlJob(){
		const jobDefinitionId = await this.differenceService.startTREControlJob();
		
	}
}

export class SavedAtValueConverter {
    toView(value: string) {
        return moment(value).format("YYYY-MM-DD HH:mm");
    }
}

export class VersionValueConverter {
    toView(value?: string) {
        if (VersionTypeMap.VersionTypeName.has(value)) {
            return VersionTypeMap.VersionTypeName.get(value);
        } else {
            return 'Okänd';
        }
    }
}	

export class VersionnumberValueConverter{
	toView(value?: string){
		if(value === "Don't exists"){
			return 'Inte tillgängligt'
		}
		else{
			return value;
		}
	}
}

interface IBounds {
	northEast: { x: number; y: number; };
	southWest: { x: number; y: number; };
}

interface IRouteDataset {
	bbox: IBounds;
	savedAt: string;
	userName: string;
}
