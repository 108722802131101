// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layer-picker-tab .layer-picker {
    position: relative;
    min-width: 450px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.layer-picker-tab .layer-picker .layer-picker-row {
    margin-top: 10px;
}

.layer-picker-tab .layer-picker .layer-picker-row receiving-sites {
    padding-left: 12px;
}

.layer-picker-tab .layer-picker .layer-groups-container {
    width: 100%;
    max-height: 450px;
    overflow-y: auto;
}

.layer-picker-tab .layer-picker .toggle-panel {
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 6px !important;
}

.layer-picker-tab .layer-picker .toggle-panel-content {
    max-height: 300px !important;
    overflow-y: scroll;
}

.layer-picker-tab .layer-picker .toggle-panel-nav {
    background-color: #777777 !important;
}

.layer-picker-tab .layer-picker .toggle-panel-nav:hover {
    background-color: #777777 !important;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/common/nav-bar-tabs/layer-picker-tab/layer-picker-tab.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;;IAEhB,2BAA2B;IAC3B,yBAAyB;IACzB,wBAAwB;IACxB,sBAAsB;IACtB,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;IAC5B,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;IACpC,WAAW;AACf","sourcesContent":[".layer-picker-tab .layer-picker {\r\n    position: relative;\r\n    min-width: 450px;\r\n\r\n    -webkit-touch-callout: none;\r\n    -webkit-user-select: none;\r\n    -khtml-user-select: none;\r\n    -moz-user-select: none;\r\n    -ms-user-select: none;\r\n    user-select: none;\r\n}\r\n\r\n.layer-picker-tab .layer-picker .layer-picker-row {\r\n    margin-top: 10px;\r\n}\r\n\r\n.layer-picker-tab .layer-picker .layer-picker-row receiving-sites {\r\n    padding-left: 12px;\r\n}\r\n\r\n.layer-picker-tab .layer-picker .layer-groups-container {\r\n    width: 100%;\r\n    max-height: 450px;\r\n    overflow-y: auto;\r\n}\r\n\r\n.layer-picker-tab .layer-picker .toggle-panel {\r\n    padding-left: 6px;\r\n    padding-right: 6px;\r\n    margin-bottom: 6px !important;\r\n}\r\n\r\n.layer-picker-tab .layer-picker .toggle-panel-content {\r\n    max-height: 300px !important;\r\n    overflow-y: scroll;\r\n}\r\n\r\n.layer-picker-tab .layer-picker .toggle-panel-nav {\r\n    background-color: #777777 !important;\r\n}\r\n\r\n.layer-picker-tab .layer-picker .toggle-panel-nav:hover {\r\n    background-color: #777777 !important;\r\n    color: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
