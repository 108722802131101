import { bindable, bindingMode, transient } from 'aurelia-framework';

@transient()
export class TogglePanel {

    @bindable title: string;

    @bindable show: boolean = true;

    @bindable loading: boolean = false;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) expanded: boolean = false;

    protected toggle(): void {
        this.expanded = !this.expanded;
    }
}